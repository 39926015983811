import axios from 'axios'

export const APIURL = process.env.REACT_APP_API_URL

export function login_with_user_module(data) {
	return axios.post(APIURL + '/auth/login_with_user_module', data)
}

export function login_with_user_module_with_user_data(data) {
	return axios.post(
		APIURL + '/auth/login_with_user_module_with_user_data',
		data,
	)
}

export function login_using_keys(data) {
	return axios.post(APIURL + '/auth/login_using_keys', data)
}

export function login_using_refresh_token(data) {
	return axios.post(APIURL + '/auth/login_using_refresh_token', data)
}

export function confirm_token() {
	return axios.get(APIURL + '/')
}
