/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { lazy } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
// import BasePage from './BasePage'
import ErrorPage from './modules/Errors/ErrorPage'
import Logout from './modules/Auth/pages/Logout'
// import { fetchBusinessData } from './modules/Business/_redux/businessActions'
const Login = lazy(() => import('./modules/Auth/pages/Login'))
const MyPages = lazy(() => import('./modules/MyPages/myPages'))
const Pricing = lazy(() => import('./modules/Pages/Pricing/pricing'))
const Booking = lazy(() => import('./modules/Pages/Booking/booking'))
const Webinar = lazy(() => import('./modules/Pages/Webinar/webinar'))
const Pages = lazy(() => import('./modules/Pages/Pages'))

export function Routes() {
	const { isAuthorized } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.user != null,
		}),
		shallowEqual,
	)
	return (
		<Switch>
			{!isAuthorized ? (
				<Route
					path='/auth/login/:status?/:token?/:refresh_token?/:user?'
					component={Login}
				/>
			) : (
				<Redirect from='/auth' to='/' />
			)}

			<Route path='/logout' component={Logout} />
			<Route path='/error' component={ErrorPage} />
			<Route exact path='/sample/pricing' component={Pricing} />
			<Route exact path='/sample/booking' component={Booking} />
			<Route exact path='/sample/webinar' component={Webinar} />
			<Route exact path='/:page_slug' component={Pages} />
			{!isAuthorized ? (
				<Redirect to='/auth/login' />
			) : (
				<Route exact path='/' component={MyPages} />
			)}
			{/* <BasePage /> */}
		</Switch>
	)
}
