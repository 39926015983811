import { login_using_refresh_token } from '../app/modules/Auth/_redux/authCrud'
import * as auth from '../app/modules/Auth/_redux/authRedux'
import Swal from 'sweetalert2'

export default function setupAxios(axios, store) {
	axios.interceptors.request.use(
		(config) => {
			const {
				auth: { authToken },
			} = store.getState()

			if (authToken) {
				config.headers.Authorization = `Bearer ${authToken}`
				config.headers.fullurl = window.location.href
			}

			return config
		},
		(err) => Promise.reject(err),
	)
	axios.interceptors.response.use(
		(response) => {
			return response
		},
		async (error) => {
			let {
				auth: { refreshToken },
			} = store.getState()
			const originalRequest = error.config
			if (
				error.response.status === 403 &&
				refreshToken &&
				!originalRequest._retry
			) {
				try {
					originalRequest._retry = true
					const splashScreen = document.getElementById('splash-screen')
					if (splashScreen) splashScreen.style.display = ''
					const response = await login_using_refresh_token({
						refresh_token: refreshToken,
					})
					if (splashScreen) splashScreen.style.display = 'none'
					if (response?.data?.status) {
						//we have token in this response
						let authToken = response.data.token
						let refreshToken = response.data.refresh_token
						let user = response.data.user

						//save this token
						store.dispatch(auth.actions.login(authToken, refreshToken, user))
						return axios(originalRequest)
					} else {
						// Swal.fire({
						// 	icon: 'error',
						// 	title: 'Session expired',
						// 	html: `<strong>Your session has expired. Login again</strong>`,
						// })
						store.dispatch(auth.actions.logout())
					}
				} catch (error) {
					// Swal.fire({
					// 	icon: 'error',
					// 	title: 'Session expired',
					// 	html: `<strong>Your session has expired. Login again</strong>`,
					// })
					store.dispatch(auth.actions.logout())
				}
			} else if (error.response.status === 401) {
				// Swal.fire({
				// 	icon: 'error',
				// 	title: 'Session expired',
				// 	html: `<strong>Your session has expired. Login again</strong>`,
				// })
				store.dispatch(auth.actions.logout())
			} else if (error.response.status === 402) {
				return Swal.fire(
					'Oops!!',
					error.response?.data?.message || 'Not Subscribed',
					'error',
				).then(() => window.location.replace('/profile/subscription'))
			} else {
				return error
			}
		},
	)
}
